import { useState } from "react";
import { Marker, Popup } from "react-map-gl";
import { Flex, Heading, Text, Image } from "@aws-amplify/ui-react";
import { Skeleton } from "@chakra-ui/react";

export function MarkerWithPopup({ latitude, longitude, title, description, images }) {
	const [showPopup, setShowPopup] = useState(false);
	const [firstImage] = images?.length ? images.slice(1) : [null];
	const handleMarkerClick = ({ originalEvent }) => {
		originalEvent.stopPropagation();
		setShowPopup(() => true);
	};
	return (
		<>
			<Marker latitude={latitude} longitude={longitude} onClick={handleMarkerClick} scale={0.8} color={"blue"} />
			{showPopup && (
				<Popup latitude={latitude} longitude={longitude} offset={25} onClose={() => setShowPopup(false)}>
					<Heading level={5}>{title}</Heading>
					<Text>{description}</Text>
					<Flex justifyContent={"center"}>
						<Skeleton isLoaded={!!firstImage}>
							<Image objectFit={"contain"} width="200px" height="200px" src={firstImage?.src} />
						</Skeleton>
					</Flex>
				</Popup>
			)}
		</>
	);
}

export const locationData = [
	{
		id: 1,
		title: "1800 Owens St, San Francisco, CA 94158",
		description: "1800 Owens St Garage",
		longitude: -122.3925183,
		latitude: 37.7655296,
	},
];
