import { useState, useEffect } from "react";
import { MapView } from "@aws-amplify/ui-react";
import { chakra, Link, Button, Box, Stack, SimpleGrid, useColorModeValue } from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { GrCar } from "react-icons/gr";
import { IoWalletOutline } from "react-icons/io5";
import { MdPayment } from "react-icons/md";
import { RiChargingPile2Line } from "react-icons/ri";
import { FaParking, FaRegClock } from "react-icons/fa";
import { PricingCard, ActionButton } from "./Pricing";
import { MarkerWithPopup, locationData } from "./Map";
import "@aws-amplify/ui-react/styles.css";
import { DataStore, Predicates, SortDirection } from "@aws-amplify/datastore";
import { Picture } from "./models";
import "./App.css";
import NavBar from "./NavBar.tsx";
import StatsCard from "./StatCard.tsx";
import ImageGallery, { ResponsiveImageData } from "./ImageGallery";
import AccessHours from "./AccessHours";

function App() {
	const [pictures, setPictures] = useState([]);
	useEffect(() => {
		const fetchPictures = async () => {
			DataStore.observeQuery(Picture, Predicates.ALL, {
				sort: (s) => s.order(SortDirection.ASCENDING),
			}).subscribe((snapshot) => {
				const { items } = snapshot;
				setPictures(items.map(ResponsiveImageData));
			});
		};
		fetchPictures();
	}, []);
	return (
		<>
			<NavBar
				items={[
					{
						label: "Access Hours",
						href: "#access-hours",
					},
					{
						label: "Facility",
						href: "#facility",
					},
					{
						label: "Location and Directions",
						href: "#map",
					},
					{
						label: "Parking Rates",
						href: "#parking-rates",
					},
				]}
			/>
			<Box pt={5} px={{ base: 2, sm: 12, md: 17 }} as="main" bg={useColorModeValue("gray.50", "gray.800")}>
				<Stack spacing="24px" as="section" maxW="7xl" mx={"auto"}>
					<chakra.h1 textAlign={"left"} fontSize={"3xl"} fontWeight={"bold"}>
						Parking @ 1800 Owens St, San Francisco, CA
					</chakra.h1>
					<ImageGallery data={pictures} />
					<chakra.h1 id="access-hours" textAlign={"left"} fontSize={"3xl"} fontWeight={"bold"}>
						Access Hours
					</chakra.h1>
					<AccessHours />
					<chakra.h1 id="facility" textAlign={"left"} fontSize={"3xl"} fontWeight={"bold"}>
						Facility
					</chakra.h1>
					<SimpleGrid columns={{ base: 1, md: 3 }} spacing={{ base: 5, lg: 8 }}>
						<StatsCard title={"Capacity"} stat={"84 spaces"} icon={<GrCar size={"3em"} />} />
						<StatsCard
							title={"Entry and Exit times"}
							stat={"24/7"}
							help={"Gated Access"}
							icon={<FaRegClock size={"3em"} />}
						/>
						<StatsCard title={"Public Parking"} stat={"Yes"} icon={<FaParking size={"3em"} />} />
						<StatsCard
							title={"EV Charging"}
							stat={"4 chargers"}
							help={"19.2kwh Level 2 chargers"}
							icon={<RiChargingPile2Line size={"3em"} />}
						/>
					</SimpleGrid>

					<chakra.h1 id="map" textAlign={"left"} fontSize={"3xl"} fontWeight={"bold"}>
						Location and Directions
					</chakra.h1>
					<Stack direction={["column", "row"]}>
						<Button
							as={Link}
							rightIcon={<ExternalLinkIcon />}
							variant="solid"
							colorScheme="blue"
							href='comgooglemaps://?saddr=&daddr=37.7655296,-122.3925183&center=37.7655296,-122.3925183&zoom=17&directionsmode=driving'
							isExternal={true}
						>
							Open Driving directions
						</Button>

						<Button
							as={Link}
							rightIcon={<ExternalLinkIcon />}
							variant="outline"
							colorScheme="blue"
							href='comgooglemapsurl://?mapmode=streetview&center=37.7655296,-122.3925183&zoom=17'
							isExternal={true}
						>
							Street View
						</Button>
					</Stack>

					<Box height="65vh" width="100%">
						<MapView
							style={{ height: "100%", width: "100%" }}
							initialViewState={{
								longitude: -122.39350997954283,
								latitude: 37.765234013908874,
								zoom: 16,
							}}
							scrollZoom={false}
							minZoom={9}
							maxZoom={18}
						>
							{locationData.map((loc) => (
								<MarkerWithPopup
									key={loc.id}
									latitude={loc.latitude}
									longitude={loc.longitude}
									title={loc.title}
									description={loc.description}
									images={pictures}
								/>
							))}
						</MapView>
					</Box>

					<chakra.h1 id="parking-rates" textAlign={"left"} fontSize={"3xl"} fontWeight={"bold"}>
						Parking Rates
					</chakra.h1>
					<SimpleGrid
						columns={{
							base: 1,
							lg: 3,
						}}
						spacing={{
							base: "8",
							lg: "0",
						}}
						maxW="7xl"
						mx="auto"
						justifyItems="center"
						alignItems="center"
					>
						<PricingCard
							data={{
								price: "$12",
								pricePer: "hour",
								name: "Drive Up",
								features: ["Cash or credit card accepted"],
							}}
							icon={GrCar}
							button={
								<ActionButton variant="outline" borderWidth="2px">
									Buy now
								</ActionButton>
							}
						/>
						<PricingCard
							zIndex={1}
							isPopular={true}
							transform={{
								lg: "scale(1.05)",
							}}
							data={{
								price: "$10",
								pricePer: "hour",
								name: "Reserve Online",
								features: ["Pay with Credit Card or Digital Wallet"],
							}}
							icon={MdPayment}
							button={<ActionButton>Buy now</ActionButton>}
						/>
						<PricingCard
							data={{
								price: "$250",
								pricePer: "month",
								name: "Monthly Parking",
								features: ["Reserved Parking Space"],
							}}
							icon={IoWalletOutline}
							button={
								<ActionButton variant="outline" borderWidth="2px">
									Buy now
								</ActionButton>
							}
						/>
					</SimpleGrid>
				</Stack>
			</Box>
		</>
	);
}

export default App;
