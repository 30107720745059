import { Table, Thead, Tbody, Tfoot, Tr, Th, Td, TableContainer } from "@chakra-ui/react";

export default function AccessHours() {
	return (
		<TableContainer border="1px" padding={"16px"} shadow={"xl"} rounded={"lg"}>
			<Table variant='simple' size="lg" colorScheme="black">
				<Thead>
					<Tr>
						<Th>Parking Type</Th>
						<Th>Access Hours</Th>
						<Th>Notes</Th>
					</Tr>
				</Thead>
				<Tbody>
					<Tr>
						<Td>Public Parking</Td>
						<Td>Monday-Friday 7am to 8pm</Td>
						<Td>Credit Card and Debit Card only—cash not accepted</Td>
					</Tr>
					<Tr>
						<Td>UCSF Hospital Parking</Td>
						<Td>Not allowed</Td>
						<Td />
					</Tr>
					<Tr>
						<Td>Chase Center Event parking</Td>
						<Td>Not allowed</Td>
						<Td />
					</Tr>
				</Tbody>
				<Tfoot>
					<Tr>
						<Th>Parking Type</Th>
						<Th>Access Hours</Th>
						<Th>Notes</Th>
					</Tr>
				</Tfoot>
			</Table>
		</TableContainer>
	);
}
