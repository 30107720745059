import { Skeleton, AspectRatio, Image, SimpleGrid } from "@chakra-ui/react";
import "photoswipe/dist/photoswipe.css";
import { Gallery, Item } from "react-photoswipe-gallery";
import "photoswipe-dynamic-caption-plugin/photoswipe-dynamic-caption-plugin.css";
import PhotoSwipeDynamicCaption from "photoswipe-dynamic-caption-plugin";
const cloudFrontUrl = "d2zfihj23hioyr.cloudfront.net";

const imageSizes = [
	{ height: 400, width: 300 },
	{ height: 800, width: 600 },
	{ height: 1600, width: 1200 },
];

const buildAwsImageUrl = (bucket, key, options) => {
	return `https://${cloudFrontUrl}/${btoa(JSON.stringify({ bucket, key, ...options }))}`;
};

export const ResponsiveImageData = ({ id, s3_bucket, s3_key, title }) => {
	const [defaultSize] = imageSizes;
	const src = buildAwsImageUrl(s3_bucket, s3_key, {
		edits: { resize: { width: defaultSize.width, height: defaultSize.height, fit: "cover" } },
	});
	const thumbnailSrc = buildAwsImageUrl(s3_bucket, s3_key, {
		edits: { resize: { width: 800, height: 800, fit: "cover" } },
	});
	const srcSet = imageSizes
		.map(({ height, width }) => {
			const url = buildAwsImageUrl(s3_bucket, s3_key, { edits: { resize: { fit: "cover", height, width } } });
			const widthDescriptor = `${width}w`;
			return `${url} ${widthDescriptor}`;
		})
		.join(", ");
	const sizes = imageSizes.map(({ width }) => `(max-width: ${width}px) 98vw`).join(", ");
	return { sizes, src, srcSet, title, thumbnailSrc, id };
};

const ImageGallery = ({ data }) => {
	return (
		<Gallery
			plugins={(pswpLightbox) => {
				new PhotoSwipeDynamicCaption(pswpLightbox, {
					captionContent: (slide) => slide.data.alt,
					type: "aside",
				});
			}}
		>
			<SimpleGrid
				padding={"16px"}
				shadow={"xl"}
				border={"1px solid"}
				borderColor={"gray.800"}
				rounded={"lg"}
				spacing='10px'
				minChildWidth={300}
			>
				{data?.length ? (
					data.map(({ id, sizes, src, srcSet, title, thumbnailSrc }) => {
						return (
							<Item
								content={
									<Image
										alt={title}
										src={thumbnailSrc}
										srcSet={srcSet}
										sizes={"(max-width: 1200px) 33.3vw, (max-width: 800px) 50vw, 100vw"}
										height="800px"
										width="600px"
										mx="auto"
									/>
								}
								key={id}
								alt={title}
							>
								{({ ref, open }) => (
									<AspectRatio ratio={1 / 1}>
										<Image
											alt={title}
											src={thumbnailSrc}
											srcSet={srcSet}
											sizes={sizes}
											ref={ref}
											boxSize={"200px"}
											onClick={open}
										/>
									</AspectRatio>
								)}
							</Item>
						);
					})
				) : (
					<Skeleton height="md" isLoaded={!!data?.length} fadeDuration={4} />
				)}
			</SimpleGrid>
		</Gallery>
	);
};

export default ImageGallery;
