import { Box, Flex, Stat, StatLabel, StatNumber, StatHelpText, useColorModeValue } from "@chakra-ui/react";
import { ReactNode } from "react";

interface StatsCardProps {
	title: string;
	stat: string;
	help?: string;
	icon: ReactNode;
}
export default function StatsCard(props: StatsCardProps) {
	const { title, stat, help, icon } = props;
	return (
		<Stat
			px={{ base: 2, md: 4 }}
			py={"5"}
			shadow={"xl"}
			border={"1px solid"}
			borderColor={useColorModeValue("gray.800", "gray.500")}
			rounded={"lg"}
		>
			<Flex justifyContent={"space-between"}>
				<Box pl={{ base: 2, md: 4 }}>
					<StatLabel fontWeight={"medium"}>{title}</StatLabel>
					<StatNumber fontSize={"2xl"} fontWeight={"medium"}>
						{stat}
					</StatNumber>
					{help && <StatHelpText fontWeight={"medium"}>{help}</StatHelpText>}
				</Box>
				<Box my={"auto"} color={useColorModeValue("gray.800", "gray.200")} alignContent={"center"}>
					{icon}
				</Box>
			</Flex>
		</Stat>
	);
}
