import React from "react";
import ReactDOM from "react-dom/client";
import { AmplifyProvider } from "@aws-amplify/ui-react";
import { Amplify } from "@aws-amplify/core";
import { AuthModeStrategyType } from "aws-amplify";
import { ChakraProvider } from "@chakra-ui/react";
import awsconfig from "./aws-exports";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

Amplify.configure({
	...awsconfig,
	DataStore: { authModeStrategyType: AuthModeStrategyType.MULTI_AUTH },
});
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<React.StrictMode>
		<AmplifyProvider>
			<ChakraProvider>
				<App />
			</ChakraProvider>
		</AmplifyProvider>
	</React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
