/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:d6263344-4aa5-4a8a-b9a3-74623e96d554",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_FvZD5ldyS",
    "aws_user_pools_web_client_id": "2lhci18eahvm6frsgp1cqcsrv1",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL",
        "PHONE_NUMBER"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "parkifylocationtempl212c58417aaa4389acd7d28fc8a54433-dev",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://3imj6j3cmzgg3osrapxn3lrmfa.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "geo": {
        "amazon_location_service": {
            "region": "us-east-1",
            "maps": {
                "items": {
                    "mapfef5949c-dev": {
                        "style": "VectorHereExplore"
                    }
                },
                "default": "mapfef5949c-dev"
            }
        }
    }
};


export default awsmobile;
